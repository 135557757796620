// load CSRF cookie
$(document).ready(function() {
    // get CSRF cookie as JSON and save it as global variable

    $.get("/api/getcsrf", function(data) {
        window.csrfTokenName = data.csrfTokenName;
        window.csrfTokenValue = data.csrfTokenValue;
    }, "json");
});


$(document).ready(function () {


    function sameHeight() {

        const mediaQuery = window.matchMedia('(min-width: 1200px)')
    
        // Check if the media query is true
        if (mediaQuery.matches && $( ".headerImageTrigger" ).length ) {


            // Then trigger an alert
            //console.log('Media Query Matched: min 992px ');

            // 1. contentblock gleich höhe wie 1. aside-block
            var rowAsideContent1Height = $( ".rowAsideContent1" ).height();
            if (rowAsideContent1Height < 150) {
                rowAsideContent1Height = 150;
            }
            $( ".rowMainContent1" ).css("height", rowAsideContent1Height);

            //var rowAsideContent2Height = $( ".rowAsideContent2" ).height();
            //$( ".rowMainContent2" ).css("height", rowAsideContent2Height);

            /*
            var sameHeightContent1Height = $( ".sameHeightContent1 p" ).height();
            var sameHeightContent2Height = $( ".sameHeightContent2 p" ).height();

            console.log('sameHeightContent1Height ' + sameHeightContent1Height);
            console.log('sameHeightContent2Height ' + sameHeightContent2Height);

            if (sameHeightContent1Height > sameHeightContent2Height) {
                $( ".sameHeightContent2" ).css("height", sameHeightContent1Height + 30);
            } else if (sameHeightContent1Height < sameHeightContent2Height) {
                $( ".sameHeightContent1" ).css("height", sameHeightContent1Height + 30);
            }
            */

        } else {
            $( ".rowMainContent1" ).css("height", "auto");
        }
    }

    sameHeight();


    $('.slider.slider-news').slick({
        accessibility: true,
        dots: false,
        infinite: true,
        prevArrow: $('.slider-news-prev'),
        nextArrow: $('.slider-news-next')
    });

    $('.slider.slider-partner-single').slick({
        accessibility: true,
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider.slider-testimonials').slick({
        accessibility: true,
        dots: false,
        infinite: true,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('.slider-testimonials-prev'),
        nextArrow: $('.slider-testimonials-next')
    });

    function slickPartner() {
        $('.slider.slider-partner').slick({
            accessibility: true,
            arrows: false,
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
        });
    }

    var timestamp = Math.floor(new Date().getTime());
    var ajaxUrl = "/ajax/ajaxPartnerHomepage?" + timestamp;
    $.ajax({type: "GET", url: ajaxUrl, success: function(result){
        $('.ajaxPartnerHomepage').html(result);
        slickPartner();
    }});



    $( ".nav-lvl1 a" ).hover(function() {
        $( ".nav-lvl2" ).addClass('d-none');
        var dataId = $(this).data("id");
        if (dataId != "undefined") {
            // console.log("dataId: " + dataId);
            $( "#nav-lvl2-" + dataId ).removeClass('d-none');
        }
    });

    $( ".searchIconWrapper button" ).click(function() {
        $( ".searchWrapper" ).toggleClass('active');
        $( ".navbarWrapper-so" ).toggleClass('active');
        $( ".navbarWrapper-fcb" ).toggleClass('active');
    });

    $('.searchWrapper input').autocomplete({
        serviceUrl: '/api/search?results=8' + $(".searchWrapper input").val(),
        minChars: 3, // min. 3 characters
        deferRequestBy: 250, // min 250ms delay
        // width: 340, // width in px
        onSelect: function (suggestion) {
            window.location.href = unescape(suggestion.data);
        }
    });


    $( window ).resize(function() {
        sameHeight();
        asideContent();
        if ( $( ".slider.slider-testimonials" ).length ) {
            $('.slider.slider-testimonials')[0].slick.refresh();
        }
    });


    function asideContent() {
        if ( $( ".rowAsideContent3" ).length ) {

            const mediaQuery = window.matchMedia('(max-width: 1199px)')
            // Check if the media query is true
            if (mediaQuery.matches) {

                if ($(".rowAsideContent1").hasClass('moved-down')) {

                } else {
                    var asideContent1 = '<div class="row rowAsideContent1">' + $(".rowAsideContent1").html() + '</div>';
                    var asideContent2 = '<div class="row rowAsideContent2">' + $(".rowAsideContent2").html() + '</div>';

                    $( ".rowAsideContent1" ).remove();
                    $( ".rowAsideContent2" ).remove();

                    $( asideContent2 ).insertAfter( ".rowAsideContent3" );
                    $( asideContent1 ).insertAfter( ".rowAsideContent3" );
                    $( ".rowAsideContent1" ).addClass('moved-down');
                    $( ".rowAsideContent2" ).addClass('moved-down');
                }

            } else {

                if ($(".rowAsideContent1").hasClass('moved-down')) {
                    var asideContent1 = '<div class="row rowAsideContent1">' + $(".rowAsideContent1").html() + '</div>';
                    var asideContent2 = '<div class="row rowAsideContent2">' + $(".rowAsideContent2").html() + '</div>';

                    $( ".rowAsideContent1" ).remove();
                    $( ".rowAsideContent2" ).remove();

                    $( asideContent1 ).insertBefore( ".rowAsideContent3" );
                    $( asideContent2 ).insertBefore( ".rowAsideContent3" );
                    $( ".rowAsideContent1" ).removeClass('moved-down');
                    $( ".rowAsideContent2" ).removeClass('moved-down');
                }
            }
        }
    }

    asideContent();

    // Gets the video src from the data-src on each button
    $('.videolink').click(function() {

        var videoSrc = $(this).data( "video" );

        var videoHeight     = $(this).data( "height" );
        var videoWidth      = $(this).data( "width" );

        var screenHeight    = $(window).height() - 60;
        var screenWidth     = $(window).width() - 60;

        var ratioH = screenHeight / videoHeight;
        var ratioW = screenWidth / videoWidth;

        //console.log("ratioH: " + ratioH);
        //console.log("ratioW: " + ratioW);
        //console.log("videoHeight: " + videoHeight);
        //console.log("videoWidth: " + videoWidth);

        if (ratioH > 1 && ratioW > 1) {
        }
        else if (ratioH < ratioW) {
            videoHeight = videoHeight * ratioH;
            videoWidth  = videoWidth *  ratioH;
            //console.log("videoHeight neu: " + videoHeight);
            //console.log("videoWidth neu: " + videoWidth);
        }
        else if (ratioW <= ratioH) {
            videoHeight = videoHeight * ratioW;
            videoWidth  = videoWidth *  ratioW;
            //console.log("videoHeight neu: " + videoHeight);
            //console.log("videoWidth neu: " + videoWidth);
        }

        $( ".modal-content" ).css("height", videoHeight + "px");
        $( ".modal-content" ).css("width", videoWidth + "px");
        $( ".modal-content" ).css("margin", "auto");

        var html = '<video controls id="video" style="width:100%;"><source src="' + videoSrc + '" type="video/mp4"></video>';
        $( ".videoWrap" ).html(html);


        $('#videoModal').on('hide.bs.modal', function(e) {
            var $v = $(e.delegateTarget).find('video');
            $v[0].pause();  // use [0] because jQuery returns a list
        });


    });

    // jobtitle ins job-form setzen.
    /*
    var title = $( ".formTitle" ).data("title");
    $( "#form-input-entryTitle" ).val(title);
    */
    
});